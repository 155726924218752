import { PencilIcon } from "@heroicons/react/24/solid";
import {
  ArrowDownTrayIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import {
  Card,
  CardHeader,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
  Avatar,
  IconButton,
  Tooltip,
  Input,
} from "@material-tailwind/react";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import moment from "moment/moment";
import ReactPaginate from "react-paginate";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { useNavigate } from "react-router-dom";

const TABLE_HEAD = [
  "S.No",
  "Request Id",
  "User Id",
  "User Name",
  // "Email",
  "Phone",
  "Requested Date",
  "Request Coins",
  "Status",
  "Action",
];

function Coins() {
  const tableRef = useRef(null);

  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [data, setData] = useState([]);
  // console.log("Data>>>>>>>>>>>>>>", data);
  const [nochangedata, setnochangedata] = useState([]);
  const getCoins = async () => {
    try {
      await axios
        .get("https://justbuygold.co.in/api/v1/coins/getCoins")
        .then((res) => {
          if (res.status === 200) {
            setData(res?.data?.success?.reverse());
            setnochangedata(res.data.success);
          } else {
            console.log(res.status.error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  // console.log("nochangedata", nochangedata);
  useEffect(() => {
    getCoins();
  }, []);

  // Customer Approved+++++++++++++++++++++++++++++++++++++++++++++++++++++

  const customerApproved = async (id, status) => {
    try {
      const config = {
        url: "/approvedhold",
        method: "put",
        baseURL: "https://justbuygold.co.in/api/v1/coins/",
        headers: { "content-type": "application/json" },
        data: {
          id: id,
          status: status,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        // console.log(res.data.success);
        alert(`${res.data.success}`);

        navigate("/bill", { state: userphone });
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Date filter
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");

  const filterData = () => {
    if (!startDate) return alert("Please select from date");
    if (!endDate) return alert("Please select to date");

    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);

    // Ensure the endDate includes the entire day
    endDateObj.setHours(23, 59, 59, 999);

    if (isNaN(startDateObj) || isNaN(endDateObj)) {
      return alert("Invalid date format");
    }

    const filteredData = nochangedata.filter((item) => {
      const itemDate = new Date(item?.createdAt);
      return itemDate >= startDateObj && itemDate <= endDateObj;
    });

    setData([...filteredData]);
  };

  // pagination+++++++++++++++++
  const [pageNumber, setPageNumber] = useState(0);
  const productPerPage = 5;
  const visitedPage = pageNumber * productPerPage;
  const displayPage = data.slice(visitedPage, visitedPage + productPerPage);

  console.log("displaypage", displayPage);

  const pageCount = Math.ceil(data.length / productPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const [search, setSearch] = useState("");
  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = nochangedata.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setData([...filterTable]);
    } else {
      setSearch(e.target.value);
      setData([...nochangedata]);
    }
  };

  const [userphone, setUserphone] = useState({});
  const [otpphone, setotpphone] = useState(" ");
  const [otpphone1, setotpphone1] = useState(" ");
  // console.log(userphone, "userPhone");
  const Otpsend = async () => {
    try {
      const config = {
        url: "/otp",
        method: "post",
        baseURL: "https://justbuygold.co.in/api/v1/user/auth",
        headers: { "content-type": "application/json" },
        data: {
          phoneno: otpphone1,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        // console.log(res.data.success);
        alert("Otp Sent Your Mobile Number");
        handleShow();
        // navigation.navigate("Otp", { phoneno });
      }
    } catch (error) {
      console.log(error.response);
      alert("Phone no is not registered");
    }
  };

  const VerfiyOtp = async () => {
    try {
      if (!otpphone) return alert("Please enter otp");
      const config = {
        url: "/otpVarification",
        method: "post",
        baseURL: "https://justbuygold.co.in/api/v1/user/auth",
        headers: { "content-type": "application/json" },
        data: {
          phoneno: otpphone1,
          otp: otpphone,
        },
      };

      let res = await axios(config);
      if (res.status === 200) {
        alert("Mobile Number Verified");
        customerApproved(userphone?._id, "Approved");
      }
    } catch (error) {
      console.log(error.response);
      if (error.response && error.response.status === 400) {
        // If the server responds with 400, OTP is incorrect
        alert("Invalid OTP. Please try again.");
      }
    }
  };

  return (
    <>
      <Card className="h-full w-full">
        <CardHeader floated={false} shadow={false} className="rounded-none">
          <div className="mb-4 flex flex-col justify-between gap-5 md:flex-row md:items-center">
            <div>
              <Typography variant="h5" color="blue-gray">
                Request Coins
              </Typography>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <div style={{ marginTop: "21px" }}>
              <div class="relative">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    class="w-4 h-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  type="search"
                  value={search}
                  onChange={handleFilter}
                  id="default-search"
                  class="block w-full pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Search user details...."
                  style={{
                    padding: "0.75rem",
                    paddingLeft: "31px",
                    outlineWidth: "0px",
                  }}
                  required
                />
              </div>
            </div>
            <div className="flex w-full shrink-0 gap-3 md:w-max">
              <div className="md:w-40">
                <label for="simple-search">From Date</label>
                <input
                  type="date"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm 
                rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                  value={startDate}
                  onChange={(e) => setstartDate(e.target.value)}
                ></input>
              </div>
              <div className="md:w-40">
                <label for="simple-search">To Date</label>
                <input
                  type="date"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                  value={endDate}
                  onChange={(e) => setendDate(e.target.value)}
                ></input>
              </div>
              <div
                className="flex w-full shrink-0 gap-1 md:w-max"
                style={{
                  marginTop: "20px",
                  marginBottom: "-16px",
                }}
              >
                <p
                  className="flex items-center text-white gap-1"
                  size="sm"
                  style={{
                    backgroundColor: "#a05b09",
                    padding: " 0px 28px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    filterData();
                  }}
                >
                  Submit
                </p>
              </div>

              <div
                className="flex w-full shrink-0 gap-1 md:w-max"
                style={{
                  marginTop: "18px",
                  // marginBottom: "-16px",
                  backgroundColor: "#a05b09",
                  cursor: "pointer",
                }}
              >
                <DownloadTableExcel
                  filename="users table"
                  sheet="users"
                  currentTableRef={tableRef.current}
                >
                  <p
                    className="flex items-center text-white gap-1"
                    size="sm"
                    style={{
                      backgroundColor: "#a05b09",
                      padding: " 0px 22px",
                      marginTop: "9px",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                      />
                    </svg>
                    Download Excel
                  </p>
                </DownloadTableExcel>
              </div>
            </div>
          </div>
        </CardHeader>
        <CardBody className="overflow-scroll px-0">
          <table
            className="w-full min-w-max table-auto text-left"
            ref={tableRef}
          >
            <thead>
              <tr>
                {TABLE_HEAD?.map((head) => (
                  <th
                    key={head}
                    className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="text-base leading-none opacity-70"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {displayPage?.map((ele, index) => {
                const isLast = index === data.length - 1;
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";

                return (
                  <tr key={ele?._id}>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal "
                      >
                        {pageNumber * productPerPage + (index + 1)}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal "
                      >
                        {ele?._id}
                      </Typography>
                    </td>

                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal "
                      >
                        {ele?.UserId}
                      </Typography>
                    </td>

                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal "
                      >
                        {ele?.username}
                      </Typography>
                    </td>
                    {/* <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal "
                      >
                        {ele?.email}
                      </Typography>
                    </td> */}

                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal "
                      >
                        {ele?.phone}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal "
                      >
                        {moment(ele?.createdAt).format("lll")}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal "
                      >
                        {ele?.coins}gm
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal "
                      >
                        {ele?.status}
                      </Typography>
                    </td>
                    <td className={classes}>
                      {ele?.status === "Requested" ? (
                        <>
                          <div className="w-max">
                            <p
                              style={{
                                color: "white",
                                cursor: "pointer",
                                border: " 1px solid red",
                                padding: " 2px 10px",
                                backgroundColor: " red",
                                borderRadius: "5px",
                                fontSize: "14px",
                                textAlign: "center",
                              }}
                              onClick={() => {
                                setotpphone1(ele?.phone);
                                setUserphone(ele);
                                handleShow1();
                                //
                              }}
                            >
                              Withdraw
                            </p>

                            {/* <p
                              style={{
                                color: "white",
                                cursor: "pointer",
                                border: " 1px solid green",
                                padding: " 2px 10px",
                                backgroundColor: " green",
                                borderRadius: "5px",
                                fontSize: "14px",
                                textAlign: "center",
                              }}
                              onClick={() => {
                                customerApproved(ele?._id, "Hold");
                              }}
                            >
                              Hold
                            </p>  */}
                          </div>
                        </>
                      ) : (
                        <>
                          {ele?.status === "Approved" ? (
                            <>
                              {/* <p
                                style={{
                                  color: "white",
                                  cursor: "pointer",
                                  border: " 1px solid green",
                                  padding: " 2px 10px",
                                  backgroundColor: " green",
                                  borderRadius: "5px",
                                  fontSize: "14px",
                                  textAlign: "center",
                                }}
                                onClick={() => {
                                  customerApproved(ele?._id, "Hold");
                                }}
                              >
                                Hold
                              </p> */}
                              <p style={{ color: "green", fontWeight: "900" }}>
                                Successfully{" "}
                              </p>
                            </>
                          ) : (
                            <>
                              <p
                                style={{
                                  color: "white",
                                  cursor: "pointer",
                                  border: " 1px solid red",
                                  padding: " 2px 10px",
                                  backgroundColor: " red",
                                  borderRadius: "5px",
                                  fontSize: "14px",
                                  textAlign: "center",
                                }}
                                onClick={() => {
                                  // customerApproved(ele?._id, "Approved");
                                  // handleShow();
                                  setotpphone1(ele?.phone);
                                  setUserphone(ele);
                                  handleShow1();
                                  // Otpsend();
                                }}
                              >
                                Withdraw
                              </p>
                            </>
                          )}
                        </>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CardBody>

        <ReactPaginate
          style={{ display: "flex", gap: "20px" }}
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
      </Card>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delivery Otp Verification </Modal.Title>
          <div>
            <input
              type="number"
              placeholder="Please enter OTP"
              className="input-add"
              value={otpphone}
              onChange={(e) => setotpphone(e.target.value)}
            />
          </div>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              VerfiyOtp();
            }}
            style={{ backgroundColor: "green", padding: "10px 20px" }}
          >
            verify
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          {/* <Modal.Title>Delivery Otp Verification </Modal.Title> */}
          <div>
            <input
              type="number"
              placeholder="Please enter phone number"
              className="input-add"
              value={otpphone1}
              onChange={(e) => setotpphone1(e.target.value)}
            />
          </div>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              Otpsend();
            }}
            style={{ backgroundColor: "green", padding: "10px 20px" }}
          >
            send otp
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Coins;
