import logo from "./logo.svg";
import "./App.css";
import { Sidebar } from "./Admin/Sidebar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Main from "./Admin/Main";
import GoldRate from "./Admin/GoldRate";
import Gst from "./Admin/Gst";
import Promovideo from "./Admin/Promovideo";
import CustomerDetails from "./Admin/CustomerDetails";
import TranscationHistory from "./Admin/TranscationHistory";
import Login from "./Admin/Login";
import Coins from "./Admin/Coins";
import { Toaster } from "react-hot-toast";
import ReferralPrice from "./Admin/ReferralPrice";
import Bill from "./Admin/Bill";

function App() {
  return (
    <div className="App">
      <Toaster position="top-center" reverseOrder={true} />
      <BrowserRouter>
        <Routes>
          <Route
            path="/gold-rate"
            element={
              <Main
                children={
                  <>
                    <GoldRate />
                  </>
                }
              />
            }
          />
          <Route path="/" element={<Login />} />

          <Route
            path="/gst"
            element={
              <Main
                children={
                  <>
                    <Gst />
                  </>
                }
              />
            }
          />
          <Route
            path="/referralprice"
            element={
              <Main
                children={
                  <>
                    <ReferralPrice />
                  </>
                }
              />
            }
          />
          <Route
            path="/promovideo"
            element={
              <Main
                children={
                  <>
                    <Promovideo />
                  </>
                }
              />
            }
          />
          <Route
            path="/customer-details"
            element={
              <Main
                children={
                  <>
                    <CustomerDetails />
                  </>
                }
              />
            }
          />
          <Route
            path="/transcation-history"
            element={
              <Main
                children={
                  <>
                    <TranscationHistory />
                  </>
                }
              />
            }
          />
          <Route
            path="/coins"
            element={
              <Main
                children={
                  <>
                    <Coins />
                  </>
                }
              />
            }
          />

<Route
            path="/bill"
            element={
              <Main
                children={
                  <>
               <Bill/>
                  </>
                }
              />
            }
          />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
