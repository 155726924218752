import { PencilIcon } from "@heroicons/react/24/solid";
import {
  ArrowDownTrayIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import {
  Card,
  CardHeader,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
  Avatar,
  IconButton,
  Tooltip,
} from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { Input } from "@material-tailwind/react";

import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import axios from "axios";

const TABLE_HEAD = ["S.No", "Added Date", "Video", "Updated Date", "Action"];

const TABLE_ROWS = [
  {
    img: "/img/logos/logo-spotify.svg",
    name: "Spotify",
    amount: "$2,500",
  },
  {
    img: "/img/logos/logo-amazon.svg",
    name: "Amazon",
    amount: "$5,000",
  },
];

function Promovideo() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [video, setvideo] = useState([]);

  const getVideo = async () => {
    try {
      await axios
        .get("https://justbuygold.co.in/api/v1/video/allvideo")
        .then((res) => {
          if (res.status === 200) {
            setvideo(res.data.success);
            console.log(res.data.success);
          } else {
            console.log(res.error);
            alert("Something went wrong");
          }
        });
    } catch (error) {
      console.log(error.success);
      alert("Video Not Add");
    }
  };

  // Add video
  const [videos, setvideos] = useState("");
  const addVideo = async () => {
    if (!videos) {
      return alert("Video Link is Required !");
    }
    try {
      const config = {
        url: "/addvideo",
        method: "post",
        baseURL: "https://justbuygold.co.in/api/v1/video",
        headers: { "content-type": "application/json" },
        data: {
          video: videos,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data.success);
        alert("Video Add");

        window.location.reload(true);
      } else {
        alert("Somthing wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  // Edit video
  const [editvideo, setEditvideo] = useState({});
  const [editvideos, seteditvideos] = useState("");
  const [edit, setedit] = useState("");
  const updateVideo = async () => {
    if (!editvideos) {
      return alert("Video Link required !!");
    }
    try {
      const config = {
        url: "/updatevideo",
        method: "put",
        baseURL: "https://justbuygold.co.in/api/v1/video",
        headers: { "content-type": "application/json" },
        data: {
          id: edit,
          video: editvideos,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        // console.log(res.data.success);

        alert("Update Video");
        window.location.reload(true);
        editvideos("");
      } else {
        alert("Somthing wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  // Delete Video

  const deleteVideo = async (id) => {
    try {
      await axios
        .delete("https://justbuygold.co.in/api/v1/video/deletevideo/" + id)
        .then((res) => {
          if (res.status === 200) {
            getVideo();
            console.log(res.data.success);
            alert("Video deleted");
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getVideo();
  }, []);

  // console.log(video, "Data Ka Welcome Kro Developer");

  return (
    <put>
      <Card className="h-full w-full">
        <CardHeader floated={false} shadow={false} className="rounded-none">
          <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
            <div>
              <Typography variant="h5" color="blue-gray">
                Promo Video
              </Typography>
            </div>
            <div className="flex w-full shrink-0 gap-1 md:w-max">
              {video?.length > 0 ? (
                ""
              ) : (
                <Button
                  onClick={handleShow}
                  className="flex items-center text-white gap-1"
                  size="sm"
                  style={{ backgroundColor: "#a05b09" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12 3.75a.75.75 0 01.75.75v6.75h6.75a.75.75 0 010 1.5h-6.75v6.75a.75.75 0 01-1.5 0v-6.75H4.5a.75.75 0 010-1.5h6.75V4.5a.75.75 0 01.75-.75z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Promo video
                </Button>
              )}
            </div>
          </div>
        </CardHeader>
        <CardBody className="overflow-scroll px-0">
          <table className="w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                {TABLE_HEAD.map((head) => (
                  <th
                    key={head}
                    className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="text-base leading-none opacity-70"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {video?.map((ele, index) => {
                const isLast = index === video.length - 1;
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";

                return (
                  <tr key={ele}>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal "
                      >
                        {index + 1}
                      </Typography>
                    </td>
                    <td>
                      {`${new Date(ele?.createdAt).getDate()}/${
                        new Date(ele?.createdAt).getMonth() + 1
                      }/${new Date(ele?.createdAt).getFullYear()}`}
                      <br></br>
                      {new Date(ele?.createdAt).getHours() +
                        ":" +
                        new Date(ele?.createdAt).getMinutes()}
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <iframe
                          style={{ width: "200px", height: "151px" }}
                          src={ele?.video}
                          // src="https://www.youtube.com/embed/jcLHBk98360?si=laX7pUzQXnwCHBnE"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowfullscreen
                        ></iframe>
                        {/* <video
                          src={`https://justbuygold.co.in/Video/${ele?.video}`}
                          muted
                          autoPlay
                          loop
                          type="video/mp4"
                          className="vohrg"
                          style={{ width: "200px", height: "151px" }}
                        /> */}
                      </div>
                    </td>
                    <td>
                      {`${new Date(ele?.updatedAt).getDate()}/${
                        new Date(ele?.updatedAt).getMonth() + 1
                      }/${new Date(ele?.updatedAt).getFullYear()}`}
                      <br></br>
                      {new Date(ele?.updatedAt).getHours() +
                        ":" +
                        new Date(ele?.updatedAt).getMinutes()}
                    </td>

                    <td className={classes}>
                      <Tooltip content="Edit Video">
                        <IconButton
                          variant="text"
                          className="relativeds"
                          onClick={() => {
                            handleShow1();
                            setedit(ele?._id);
                            setEditvideo(ele);
                          }}
                        >
                          <PencilIcon className="relative pl-2 h-6 w-6" />
                        </IconButton>
                      </Tooltip>
                      {/* <Tooltip content="Delete Video">
                        <IconButton
                          variant="text"
                          className="relativeds"
                          onClick={() => {
                            deleteVideo(ele?._id);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="relative pl-2 h-6 w-6"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </IconButton>
                      </Tooltip> */}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CardBody>
      </Card>
      {/* Add Video */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            <Typography variant="h5" color="blue-gray">
              Promo Video
            </Typography>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="add-data">
            <Form>
              <div className="aaa">
                <label
                  htmlFor="files"
                  style={{ fontSize: "20px", paddingBottom: "8px" }}
                >
                  Video
                </label>
                <br />
                <input
                  type="text"
                  placeholder="Add your video link"
                  className="input-add"
                  // value={videos}
                  onChange={(e) => {
                    setvideos(e.target.value);
                  }}
                />
              </div>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button
            className="px-3"
            size="sm"
            style={{ backgroundColor: "#a05b09" }}
            onClick={handleClose}
          >
            Close
          </Button> */}
          {/* <Button variant="gradient" style={{ backgroundColor: "#a05b09" }}>
            gradient
          </Button> */}
          <Button
            className="px-3"
            size="sm"
            style={{ backgroundColor: "#a05b09" }}
            onClick={addVideo}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Edit Video */}
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            <Typography variant="h5" color="blue-gray">
              Update Promo Video
            </Typography>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="add-data">
            <Form>
              <div className="aaa">
                <label
                  style={{ fontSize: "20px", paddingBottom: "8px" }}
                  htmlFor="upload1"
                >
                  Video
                </label>
                <br />
                <input
                  type="text"
                  // value={editvideos}
                  placeholder={editvideo?.video}
                  className="input-add"
                  onChange={(e) => seteditvideos(e.target.value)}
                />
              </div>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button
            className="px-3"
            size="sm"
            style={{ backgroundColor: "#a05b09" }}
            onClick={handleClose1}
          >
            Close
          </Button> */}
          {/* <Button variant="gradient" style={{ backgroundColor: "#a05b09" }}>
            gradient
          </Button> */}
          <Button
            className="px-3"
            size="sm"
            style={{ backgroundColor: "#a05b09" }}
            onClick={updateVideo}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </put>
  );
}

export default Promovideo;
