import React, { useEffect, useState } from "react";
import "./bill.css";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { useRef } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

function Bill() {
  const contentRef = useRef();

  const location = useLocation();
  const { state } = location;

  console.log("state", state);

  const [alltransiction, setAlltransiction] = useState([]);
  // console.log('History', alltransiction);
  const userTransaction = async (_id) => {
    try {
      await axios
        .get(
          "https://justbuygold.co.in/api/v1/transactions/transactionhistory/" +
            state?.UserId
        )
        .then((res) => {
          if (res.status == 200) {
            setAlltransiction(res.data.success);
          } else {
            console.log(res.error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const [allcoin, setallcoin] = useState([]);
  const getCoins = async () => {
    try {
      const res = await axios.get(
        `https://justbuygold.co.in/api/v1/coins/singalcoins/${state?.UserId}`
      );

      if (res.status === 200) {
        setallcoin(res.data.success);
        // console.log(res.data.success);
      } else {
        console.log(res.error);
        // Alert.alert('Error', 'Failed to fetch coins data.');
      }
    } catch (error) {
      console.log(error);
      // Alert.alert('Error', 'An error occurred while fetching coins data.');
    } finally {
    }
  };
  // console.log("nochangedata", nochangedata);
  useEffect(() => {
    userTransaction();
    getCoins();
  }, []);

  const transicitionData = alltransiction?.reduce(
    (a, i) => a + Number(i?.gold),
    0
  );
  // console.log('transicitionData', transicitionData);

  // All gold Store
  const coinsData = allcoin
    // ?.filter((ele) => ele?.status == "Approved")
    .reduce((a, i) => a + Number(i?.coins || 0), 0);
  // console.log(allcoin, 'coinsData');
  // Total Store gold
  const totalgoldStore = transicitionData - coinsData;
  // console.log('Total gold Store', totalgoldStore);

  const downloadPDF = async () => {
    const element = contentRef.current;
    const canvas = await html2canvas(element);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");

    // Calculate width and height
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

    // Add image to PDF
    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

    // Save the PDF
    pdf.save("bill.pdf");
  };

  function generateUniquePrefixedNumber(
    prefix = "JBG",
    existingNumbers = new Set()
  ) {
    let number;

    // Keep generating until a unique number is found
    do {
      const randomNumber = Math.floor(100000 + Math.random() * 900000); // Ensures a 6-digit number
      number = `${prefix}${randomNumber}`; // Concatenate the prefix and random number
    } while (existingNumbers.has(number));

    // Add the generated number to the set of existing numbers
    existingNumbers.add(number);

    return number;
  }

  // Usage example
  const existingNumbers = new Set(); // Track already generated numbers
  const billno = generateUniquePrefixedNumber("JBG", existingNumbers); // Outputs a unique number starting with JBG
  const today = new Date();
  const formattedDate = today.toLocaleDateString("en-GB");
  return (
    <div className="w-100  d-flex flex-column  align-items-center">
      <button
        onClick={downloadPDF}
        style={{
          marginTop: "20px",
          padding: "10px 20px",
          fontSize: "16px",
          backgroundColor: "#a05b09",
          color: "white",
          border: "none",
          cursor: "pointer",
        }}
      >
        Download
      </button>

      <div className="billmain border" ref={contentRef}>
        <div className="headerpart">
          <div className="left">
            <div className="goldlogo">
              <img src="../Buygold2.jpg" alt="" style={{ width: "80px" }} />
            </div>
          </div>
          <div className="right">
            <div className="companyname">
              <img src="../justbuygold.jpg" alt="" style={{ width: "250px" }} />
            </div>
            Ground Floor ,No. 03 , 9th A Main Road ,Byraweshwara Nagar ,
            Nagarbhavi, Bengaluru, Karnataka, 560072
            <br />
            Phone : 9019095596 , 9019070105
            <br />
            justbuygold100@gmail.com
            <br />
            GST No : 29PEOPS8833A2ZM
          </div>
        </div>
        <div className="billdetail">
          <div className="billno">Bill No : {billno}</div>
          <div className="billdate">Bill Date : {formattedDate}</div>
        </div>

        <div className="customerpart mt-1">
          <div className="d-flex">
            <div style={{ width: "130px" }}>Name :</div>

            <div>{state?.username}</div>
          </div>

          <div className="d-flex">
            <div style={{ width: "130px" }}>Phone :</div>

            <div>{state?.phone}</div>
          </div>

          <div className="d-flex">
            <div style={{ width: "130px" }}>Request Id :</div>

            <div>{state?.UserId}</div>
          </div>

          <div className="d-flex">
            <div style={{ width: "130px" }}>Withdraw Date :</div>

            <div>{formattedDate}</div>
          </div>
        </div>

        <div className="golddetails">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Gold Type</th>
                <th scope="col">Stored Gold</th>
                <th scope="col">Withdraw Gold</th>
                <th scope="col">Available Gold</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>24K/Coin</td>
                <td>{transicitionData} gm</td>
                <td>{state?.coins} gm</td>
                <td>{totalgoldStore} gm</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="mt-5  w-100 d-flex justify-content-end pe-5">
          <p>Reciever Signature</p>
        </div>

        <div className="mt-5 position-absolute bottom-0 pb-2">
          <small>This is computer generated bill no signature required.</small>
        </div>
      </div>
    </div>
  );
}

export default Bill;
