import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";

function Login() {
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [acc, setAcc] = useState(true);
  const adminLogin = async (e) => {
    e.preventDefault();
    try {
      if (!email) {
        return toast.error("Email Required !!!");
      }
      if (!password) {
        return toast.error("Password Required !!!");
      }
      const config = {
        url: "/login",
        method: "post",
        baseURL: "https://justbuygold.co.in/api/v1/admin",
        headers: { "content-type": "application/json" },
        data: {
          email: email,
          password: password,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        sessionStorage.setItem("admin", JSON.stringify(res.data.success));
        alert("Successfully Login");
        window.location.assign("/gold-rate");
      }
    } catch (error) {
      alert(error.response.data.error);
      // console.log("Error==>", error);
    }
  };

  return (
    <>
      <div>
        <section class="rounded-md  p-2">
          <div class="flex items-center justify-center bg-white px-4 py-10 sm:px-6 sm:py-16 lg:px-8">
            <div
              class="xl:mx-auto xl:w-full xl:max-w-sm 2xl:max-w-md"
              style={{
                boxShadow:
                  " 0 1px 1px rgba(0,0,0,0.15) ,0 2px 2px rgba(0,0,0,0.15), 0 4px 4px rgba(0,0,0,0.15), 0 8px 8px rgba(0,0,0,0.15)",
                padding: "15px",
                width: "",
              }}
            >
              <div
                class="mb-2"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src="../Buygold.jpg"
                  alt=""
                  style={{ width: "230px", height: "110px" }}
                />
              </div>
              <h2 class="text-2xl font-bold leading-tight text-black">
                Sign in to your account
              </h2>

              <form class="mt-8" onSubmit={adminLogin}>
                <div class="space-y-5">
                  <div>
                    <label for="" class="text-base font-bold text-gray-900">
                      {" "}
                      Email Address{" "}
                    </label>
                    <div class="mt-2">
                      <input
                        class="flex h-10 w-full rounded-md border-solid border-2 border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => {
                          setemail(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <div class="flex items-center justify-between">
                      <label for="" class="text-base font-bold text-gray-900">
                        {" "}
                        Password{" "}
                      </label>
                    </div>
                    <div class="mt-2" style={{ position: "relative" }}>
                      <input
                        class="flex h-10 w-full rounded-md border-solid border-2 border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                        type={acc ? "password" : "text"}
                        placeholder="Password"
                        value={password}
                        onChange={(e) => {
                          setpassword(e.target.value);
                        }}
                      />
                      {acc ? (
                        <>
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                            style={{
                              position: "absolute",
                              right: "10px",
                              top: "8px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setAcc(!acc);
                            }}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                            />
                          </svg>
                        </>
                      ) : (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                            style={{
                              position: "absolute",
                              right: "10px",
                              top: "8px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setAcc(!acc);
                            }}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                          </svg>
                        </>
                      )}
                    </div>
                  </div>
                  <div>
                    <button
                      target="_blank"
                      type="submit"
                      class="inline-flex w-full items-center justify-center rounded-md  px-3.5 py-2.5 font-semibold leading-7 text-white hover:bg-black/80"
                      style={{ backgroundColor: "#a05b09" }}
                    >
                      Sign in{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="ml-2"
                      >
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                        <polyline points="12 5 19 12 12 19"></polyline>
                      </svg>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Login;
